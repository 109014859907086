import React, { useEffect, useState } from "react"
import { css } from "@emotion/react"
import { navigate, Link } from "gatsby"
import Layout from "../components/layout"
import SuccessMessage from "../components/successmessage"
import { Row, Col, Form, Button, ListGroup, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Obfuscate from "react-obfuscate"; // um Telefonnummer und E-Mail vor Spam zu schützen
import SEO from "../components/seo"
import ContentContainer from "../components/contentcontainer"

const ContactListGroupItem = props => (
  <ListGroup.Item action css={css`padding-left: 10px`}>
    {/* Der <Link> würde alle Elemente wie einen Standard-Link stylen (das bedeutet: unterstrichen und in Primary-Farbe)
    Um das zu verhindern, müssen wir den Link mit css stylen für standard und für hover
    es wird einfach inherit verwendet und die Eigenschaften vom Parent geerbt;
    es ginge aber auch text-decoration: none und color: black */}
    <Link to={props.linkTo} css={css`text-decoration: inherit; color: inherit; &:hover {text-decoration: inherit; color: inherit;}`}>
      <Row>
        <Col xs="10">
          {props.children}
        </Col>
        <Col xs="2" className="d-flex align-content-center justify-content-end flex-wrap">
          <div><FontAwesomeIcon icon={faChevronRight} className="mr-3" size="lg" /></div>
        </Col>
      </Row>
    </Link>
  </ListGroup.Item>
)


const ContactDetails = props => (
  <div>
    <Row className="mb-3">
      <Col className="d-flex align-items-center">
        <FontAwesomeIcon icon={faPhone} className="mr-3" size="lg" />
        {/* <span><a href="tel: +49 151 12345678" className="text-decoration-none">+49 151 12345678</a></span> */}
        <span><Obfuscate tel="+49 221 95490630" className="text-decoration-none"></Obfuscate></span>
      </Col>
    </Row>
    <Row className="mb-3">
      <Col className="d-flex align-items-center">
        <FontAwesomeIcon icon={faWhatsapp} className="mr-3" size="lg" />
        <span><a href="https://wa.me/message/KQFSXNNBN7A2H1">@klitzekleinundmagisch</a></span>
      </Col>
    </Row>
    <Row>
      <Col className="d-flex align-items-center">
        <FontAwesomeIcon icon={faEnvelope} className="mr-3" size="lg" />
        <span><Obfuscate email="jo@klitzekleinundmagisch.de"></Obfuscate></span>
      </Col>
    </Row>
  </div>
)

const ContactForm = props => {

  // VALIDIERUNG DER FORMFELDER
  // man kann mit noValidate in der Form verhindern, dass der Browser eine Standardvalidierung macht (die nicht schön aussieht)
  // dann müssen wir uns aber selbst um die Validierung kümmern (dafür ist die Funktion unten)
  // VORHER: bevor ich das eingebaut habe wurde bei Submit direkt die Funktion props.handleSendClick ausgeführt
  // das lokale handleSubmit wurde hier als Zwischenebene eingezogen
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget
    // zuerst wird mit checkValidity geprüft ob alle Checks okay sind (required Felder auch ausgefüllt sind)
    if (form.checkValidity() === false) {
      // fall nein, setze validated auf true und zeige damit die Validierungs-UI an (Felder grün und rot markiert)
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    }
    else {
      // falls die Validierung okay war, führe die Funktion aus, die über die Callbacks übergeben wurde
      // leite hier das event durch, damit von außen die Inhalte der Form ausgelesen werden können
      props.handleSendClick(event)
    }
  }

  // LOKALER STYLE
  const contactFormStyle = css`
  label {
    font-size: 80%;
    text-transform: uppercase;
    font-weight: bold;
  }
  `

  return (
    // Das noValidate ist notwendig, um das Standard-Browser-Verhalten zur Formvalidierung zu unterbinden
    <Form css={contactFormStyle} onSubmit={handleSubmit} noValidate validated={validated}>
      <Form.Row>
        <Col>
          <Form.Group controlId="contactForm.firstname">
            <Form.Label>Vorname</Form.Label>
            <Form.Control type="text" name="firstname" placeholder="Vorname" required />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="contactForm.lastname">
            <Form.Label>Nachname</Form.Label>
            <Form.Control type="text" name="lastname" placeholder="Nachname" required />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Group controlId="contactForm.email">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" name="email" placeholder="name@example.com" required />
      </Form.Group>
      <Form.Group controlId="contactForm.ControlSelectService">
        <Form.Label>Für welche Leistung interessierst du dich?</Form.Label>
        <Form.Control as="select" name="topic">
          <option>Allgemeines Interesse</option>
          <option>Babybauchfotos</option>
          <option>Newborn-Fotos</option>
          <option>Babyfotos</option>
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="contactForm.text">
        <Form.Label>Zusätzliche Details</Form.Label>
        <Form.Control as="textarea" rows={3} name="details" />
      </Form.Group>
      <Form.Group controlId="contactForm.hp" className="d-none">
        <Form.Control type="text" name="hp" />
      </Form.Group>
      <Button type="submit" variant="primary" block className="mb-5">
        {props.showSpinner && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="mr-2" />}
      Senden
      </Button>
    </Form>
  )
}

const NextBestActionList = props => (
  <ListGroup variant="flush">
    <ContactListGroupItem linkTo="/contact/reservation/">
      <h6>Wunschtermin reservieren</h6>
      <small>Möchtest du deinen Wunschtermin reservieren? Kein Problem: prüfe hier unverbindlich, ob dein Termin noch frei ist.</small>
    </ContactListGroupItem>
    <ContactListGroupItem linkTo="/contact/calculatedate/">
      <h6>Besten Termin für Shooting berechnen</h6>
      <small>Du fragst dich, welches wohl der besten Termin für ein Shooting wäre? Kein Problem, wir haben hier viel Erfahrung. Berechne hier den besten Zeitpunkt für das Shooting.</small>
    </ContactListGroupItem>
    <ContactListGroupItem linkTo="/contact/callback/">
      <h6>Rückruf vereinbaren</h6>
      <small>Du möchtest, dass wir uns bei dir melden? Sehr gerne! Wenn du uns deine Kontaktdaten gibst, rufen wir dich gern zurück!</small>
    </ContactListGroupItem>
  </ListGroup>
)


export default function Contact(props) {

  const [showSpinner, setShowSpinner] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showContactSite, setShowContactSite] = useState(true);

  // Dieser useEffect Hook dient dazu die Seite zu resetten, wenn über den NavLink der customnavbar
  // wieder auf die Seite gegangen wird
  // sollte vorher die SuccessMessage angezeigt werden wird diese wieder ausgeblendet
  // dafür überwacht der Hook props.location.state.timestamp
  // diese Prop kommt über den <Link> aus der customnavbar übergeben
  // und enthält einen Timestemp, damit die Variable sich auch bloss jedes Mal ändert, wenn auf den Link geklickt wird
  // wenn sich die Variable ändert, wird der Hook ausgelöst
  // die ? in [props?.location?.state?.timestamp] dietennen dem Abfangen von Fehlern, falls die Prop nicht gesetzt ist
  // (das ist hier immer der Fall, wenn die Seite nicht über die Navbar aufgerufen wird)
  // hierüber hat sich ESLint aber beschwert
  // Deshalb: einfach nur props.location.state verwendet, damit man nicht einfach auf eine nicht vorhandens Element eines Array zurgreift

  useEffect(() => {
    setShowSuccessMessage(false)
    setShowContactSite(true)
    setShowSpinner(false)
  }, [props.location.state])

  // Funktion die sich um den Senden-Button kümmert
  const handleSendClick = (e) => {
    // die normale Weiterleitung verhindern
    e.preventDefault()
    // setze showSpinner auf True, damit der Spinner angezeigt wird
    setShowSpinner(true)

    // Definiere neues Objekt formData mit allen Daten des Formulars
    const formData = new FormData(e.target)
    // ergänze ein weiteres Feld in den formData (damit wir in der E-Mail später wissen, wo die Daten herkommen)
    formData.append("subject", "Kontaktformular")
    // Fülle ein formDataObj mit allen Einträgen aus dem Formular
    const formDataObj = Object.fromEntries(formData.entries())
    // DEBUG
    // console.log(formData)
    // console.log(formDataObj)
    // console.log(JSON.stringify(formDataObj))

    // verwende die API, um Daten per POST dorthin zu senden
    // Vorsicht: als Body darf dort weder formData stehen (ist leer)
    // noch formDataObj -> das ist ein Object, wir brauchen aber JSON-Daten
    // also das Objekt noch in JSON stringifien
    // console.log(`${process.env.CONTACTMAIL_API_URL}`)
    fetch(`${process.env.CONTACTMAIL_API_URL}`, {
      method: 'POST',
      body: JSON.stringify(formDataObj),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => res.json())
      .then((data) => {
        // Success!
        // console.log('Success:', data); // DEBUG
        // Wenn der Request erfolgreich war, dann blende die Kontaktseite aus
        setShowContactSite(false);
        // und blende die Erfolgsmeldung ein
        setShowSuccessMessage(true);
      })
      .catch((error) => {
        // Error!
        // console.error('Error:', error); // DEBUG
        navigate('/404/')
      });
  }


  const contactContainerStyle = css`
  margin-left: 2em;
  margin-right: 2em;
  `

  return (
    <Layout>
      <ContentContainer>
        <SEO
          title="Kontakt Jovana Wulf | Babyfotografin Köln"
          description="Hier kannst du direkt den besten Termin für dein Shooting bestimmen und deinen Wunschtermin reservieren. Oder du schreibst mir einfach und wir besprechen alles weitere. "
        />
        <div css={contactContainerStyle} style={showContactSite ? {} : { display: 'none' }}>
          <Row>
            {/* Beachte: hier ist die Reihenfolge der COLs umgekehrt, als sie später dargestellt wird;
            sie wird wieder zurückgedreht über order-sm-1 bzw. order-sm-12
            Hintergrund: in mobile soll die rechte Hälfte der Seite (also die Wizards) OBEN dargestellt werden, erst darunter dann die Contact-Form*/}
            <Col xs={12} sm={6} className="mb-5 offset-sm-1 order-sm-12">
              {/* Wir brauchen hier auch die margin-right, damit es in einer Linie mit der GroupList ist
              Alternative wäre hier nur kein Padding bei den List-Items zu haben; das sieht aber total doof aus, weil dann die AktivierungsBox bei Hover direkt anliegt)*/}
              <div className="mb-4 mb-sm-5 ml-2">
                <h4>Wie können wir dir helfen?</h4>
                <p>Unten kannst du auswählen, wie wir dir weiterhelfen können. Falls nicht das passende dabei ist, nutze gern unser Kontakt&shy;formular auf der linken Seite.</p>
              </div>
              <NextBestActionList></NextBestActionList>
            </Col>
            <Col xs={12} sm={5} className="mb-5 order-sm-1">
              <ContactForm handleSendClick={handleSendClick} showSpinner={showSpinner}></ContactForm>
              <ContactDetails></ContactDetails>
            </Col>
          </Row>
        </div>
        {showSuccessMessage && <SuccessMessage>Wir haben deine Anfrage erhalten und melden uns baldmöglichst bei dir</SuccessMessage>}
      </ContentContainer>
    </Layout>
  )
}
